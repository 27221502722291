<template>
  <div class="manager-container">
    <template v-if="pageVisible">
      <div class="left">
        <base-block-title
          v-if="hasSysAdminPermission"
          :title="$t('company')"
          :sub-title="companyList.length">
        </base-block-title>
        <div class="content">
          <div
            class="company-name"
            :class="{active:company.id === currentCompany.id}"
            v-for="company in (hasSysAdminPermission ? companyList : [this.currentCompany])"
            :key="company.id"
            @click="handleCompanyClick(company)">
            <div
              :class="{active:company.id === currentCompany.id && !currentTeam.id}"
              @click.stop="handleCompanyTitleClick(company)">
              <em class="el-icon-suitcase"></em>
              {{ company.companyName }}
            </div>
            <template
              v-if="currentCompany.id === company.id && !teamLoading">
              <div
                class="team-name"
                :class="{active:team.id === currentTeam.id}"
                v-for="team in teams || []"
                :key="team.id"
                @click.stop="onTeamClick(team)">
                <em style="margin-left: 10px" class="el-icon-files"></em>
                {{ team.name }}
              </div>
            </template>
            <el-dropdown
              v-if="(currentCompany.id === company.id && hasPermission($permissionFunctionCode.ADD))"
              @command="handleTeam">
              <em class="el-icon-more icon-color"></em>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="add">{{ $t('addTeam') }}</el-dropdown-item>
                <el-dropdown-item
                  :disabled="!currentTeam.id || currentTeam.type === 0"
                  command="edit">
                  {{ $t('editTeam') }}
                </el-dropdown-item>
                <el-dropdown-item
                  :style="{color: !currentTeam.id || currentTeam.type === 0?'#bbb':'#F56C6C'}"
                  :disabled="!currentTeam.id || currentTeam.type === 0"
                  command="delete">
                  {{ $t('deleteTeam') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title-button" v-if="hasSysAdminPermission">
          <base-block-title
            :title="$t('user')"
            :sub-title="currentTeam.name || currentCompany.companyName">
          </base-block-title>
        </div>
        <div class="user-container">
          <base-no-data v-if="!users.length"></base-no-data>
          <div class="users">
            <user-card
              v-for="user in users"
              :key="user.id"
              :user="user"
              :teams="teams || []"
              :company="currentCompany"
              @handle-success="onUserHandleSuccess">
            </user-card>
          </div>
        </div>
      </div>
    </template>

    <!--重置密码弹窗-->
    <el-dialog
      :visible.sync="changePasswordVisible"
      width="40%">
      <base-title slot="title" :text="$t('resetPassword')"></base-title>
      <div style="padding: 20px">
        <el-form
          ref="pswForm"
          :model="pswFormData"
          :rules="rules"
          :label-width="$i18n.locale==='cn'?'80px':'160px'">
          <el-form-item
            :label="$t('username')">
            <el-input type="text" :value="currentUser.name" disabled></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('newPsw')"
            prop="password">
            <el-input type="password" v-model="pswFormData.password"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('confirmPaw')"
            prop="confirmPaw">
            <el-input type="password" v-model="pswFormData.confirmPaw"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          @click="changePasswordVisible = false">
          {{ $t('cancel') }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="submitResetPassword">
          {{ $t('submit') }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 搜索弹窗-->
    <el-dialog
      :visible.sync="queryVisible"
      width="30%"
      @closed="queryFormData.name=''">
      <base-title slot="title" :text="$t('query')"></base-title>
      <div style="padding: 0 30px">
        <el-input
          v-model.trim="queryFormData.name"
          :placeholder="$t('fullname')"
          @keyup.enter.native="submitQuery">
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          @click="queryVisible = false">
          {{ $t('cancel') }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="submitQuery">
          {{ $t('submit') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import baseMixin from '@/mixins/base-mixin'
import baseTableMixin from '@/mixins/base-table-mixin'
import UserCard from '@/components/UserCard'

export default {
  name: 'TeamManager',
  mixins: [baseMixin, baseTableMixin],
  components: { UserCard },
  data () {
    return {
      companyList: [],
      users: [],
      teams: [],
      currentCompany: {},
      currentTeam: {},
      currentUser: {},
      roleVisible: false,
      selectedRoles: [],
      roles: [],
      changePasswordVisible: false,
      pswFormData: {
        password: '',
        confirmPaw: ''
      },
      rules: {
        password: null,
        confirmPaw: null
      },
      queryVisible: false,
      queryFormData: {
        name: ''
      },
      userFields: [
        'email',
        'firstName',
        'lastName',
        'homePhoneNumber',
        'salutation',
        'officePhoneNumber',
        'designation'
      ],
      pageVisible: true,
      teamLoading: false
    }
  },

  created () {
    const _this = this
    const commonVerify = [
      {
        required: true,
        message: _this.$t('pswNoEmpty'),
        trigger: 'blur'
      },
      {
        min: 6,
        max: 12,
        message: _this.$t('pswLength'),
        trigger: 'blur'
      }
    ]
    const validatePsw = (rule, value, callback) => {
      if (value !== _this.pswFormData.password) {
        callback(new Error(_this.$t('pswNotMatch')))
      } else {
        callback()
      }
    }
    this.rules.password = [...commonVerify]
    this.rules.confirmPaw = [
      ...commonVerify,
      {
        validator: validatePsw,
        trigger: 'blur'
      }]
    this.queryUrl = this.$apis.queryUser
    this.saveUrl = this.$apis.saveUser
    this.deleteUrl = this.$apis.deleteUser
    this.pageSize = 10000
    this.getCompanies()
  },

  computed: {
    ...mapState(['permissions']),
    hasSysAdminPermission () {
      return this.permissions.hasSysAdminPermission
    }
  },

  methods: {
    getCompanies () {
      /**
       * 获取企业列表
       */
      const assignCurrentCompany = async () => {
        if(this.companyList.length <= 0) return
        this.currentCompany = this.companyList[0]
      }

      return new Promise((resolve, reject) => {
        this.fullScreenLoading = true
        if(this.hasSysAdminPermission) {
          this.$request.get({
            url: `${this.$apis.companyBaseUrl}?pageNumber=1&pageSize=99999&offline=false`,
          }).then(res => {
            if (res?.code === 1000) {
              this.companyList = res?.data?.records || []
              assignCurrentCompany().then(() => {
                this.fullScreenLoading = false
                resolve()
              })
            }
          })
        }
        else {
          const { currentCompany: globalCurrentCompany } = this.$store.state
          this.companyList = globalCurrentCompany ? [globalCurrentCompany] : []
          assignCurrentCompany().then(() => {
            this.fullScreenLoading = false
            resolve()
          })
        }
      })
    },

    getTeamListByCompany (company) {
      /**
       * 根据机构获取团队列表
       */
      this.teamLoading = true
      this.$request.get({
        url: `${this.$apis.teamBaseUrl}?pageNumber=1&pageSize=99999&organizationId=${company.id}`,
      }).then(res => {
        if (res?.code === 1000) {
          this.currentTeam = {}
          this.teams = res.data.records
        }
        // this.users = []
        this.teamLoading = false
      })
    },

    getUserList (orgId, teamId = undefined) {
      /**
       * 获取用户列表
       */
      this.$request.get(
        {
          url: `${this.$apis.userExtBaseUrl}?pageNumber=1&pageSize=99999&organizationId=${this.currentCompany.id}` + (teamId ? `&teamIds=${teamId}` : ''),
        }).then(data => {
        // 返回的数据格式和后端约定
        if (data?.code === 1000) {
          const users = data?.data?.records || []
          const { id } = this.$store.state.user
          const currentUserIndex = users.findIndex(item => item.id === id)
          if (currentUserIndex) {
            users.unshift(users[currentUserIndex])
            users.splice(currentUserIndex + 1, 1)
          }
          this.users = users
          this.total = data.data.total
        }
      })
    },

    addTeam () {
      /**
       * 增加团队
       */
      this.$prompt(this.$t('inputTeamName'), this.$t('addTeam'), {
        confirmButtonText: this.$t('submit'),
        cancelButtonText: this.$t('cancel'),
        inputPattern: /.+/,
        inputErrorMessage: this.$t('cannotBeEmpty')
      }).then(({ value }) => {
        this.$request.post({
          url: this.$apis.saveTeam,
          data: {
            orgId: this.currentCompany.id,
            name: value,
            type: 'COMMON'
          }
        }).then(res => {
          if (res?.code === 1000) {
            this.getTeamListByCompany(this.currentCompany)
            this.$message.success('Team has been added.')
          }
        })
      }).catch(() => {
      })
    },

    editTeam () {
      /**
       * 编辑团队
       */
      this.$prompt(this.$t('inputTeamName'), this.$t('editTeam'), {
        confirmButtonText: this.$t('submit'),
        cancelButtonText: this.$t('cancel'),
        inputPattern: /.+/,
        inputErrorMessage: this.$t('cannotBeEmpty'),
        inputValue: this.currentTeam.name
      }).then(({ value }) => {
        this.$request.post({
          url: this.$apis.saveTeam,
          data: {
            id: this.currentTeam.id,
            name: value
          }
        }).then(res => {
          if (res?.code === 1000) {
            this.getTeamListByCompany(this.currentCompany)
            this.$message.success(this.$t('dataModifySuccess'))
          }
        })
      }).catch(() => {
      })
    },

    deleteTeam () {
      /**
       * 删除团队
       */
      this.$confirm(this.$t('deletePermanent'), this.$t('deleteTeam'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.$request.post({
          url: this.$apis.deleteTeam,
          data: [this.currentTeam.id]
        }).then(res => {
          if (res?.code === 1000) {
            this.getTeamListByCompany(this.currentCompany)
            this.$message.success('Team has been deleted.')
          }
        })
      }).catch(() => {
      })
    },

    handleTeam (command) {
      /**
       * 操作团队数据
       */
      const doList = {
        add: this.addTeam,
        edit: this.editTeam,
        delete: this.deleteTeam
      }
      doList[command]()
    },

    onUserHandleSuccess () {
      /**
       * 监听操作用户成功，刷新用户列表
       */
      const { id: teamId } = this.currentTeam
      teamId ? this.getUserList(this.currentCompany.id, teamId) : this.getUserList(this.currentCompany.id)
    },

    inviteUser (roleCode) {
      /**
       * 邀请用户
       */
      if (!this.currentTeam.id) {
        return this.$message.error(this.$t('selectTeam'))
      }
      if (!this.currentCompany.id) {
        return this.$message.error(this.$t('selectCompany'))
      }
      const title = roleCode === this.$roleCode.teamManager ? this.$t('inviteTeamManager') : this.$t('inviteTeamMember')
      this.$prompt(this.$t('email'), title, {
        confirmButtonText: this.$t('invite'),
        cancelButtonText: this.$t('cancel'),
        inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputErrorMessage: this.$t('emailFormatError')
      }).then(({ value }) => {
        this.$request.post({
          url: this.$apis.inviteUser,
          data: {
            email: value,
            orgId: this.currentCompany.id,
            roleCode,
            teamId: this.currentTeam.id
          }
        }).then(res => {
          if (res?.code === 1000) {
            this.$message.success(this.$t('invitedMailSentSuccess'))
          }
        })
      }).catch(() => {
      })
    },

    handleCompanyClick (company) {
      /**
       * 监听企业被点击，获取部门列表和用户列表
       */
      if (this.currentCompany.id !== company.id) {
        this.currentCompany = company
        this.currentTeam = {}
        this.teams = company.teams || []
        // this.getTeamListByCompany(company)
        this.getUserList(company.id)
      }
    },

    handleCompanyTitleClick (company) {
      if (this.currentCompany.id === company.id) {
        this.getUserList(company.id)
        this.currentTeam = {}
      } else {
        this.handleCompanyClick(company)
      }
    },

    onTeamClick (team) {
      /**
       * 监听团队被点击，获取用户列表
       */
      this.currentTeam = team
      this.getUserList(this.currentCompany.id, team.id)
    },

    resetPassword (row) {
      /**
       * 重置密码
       */
      this.currentUser = row
      this.changePasswordVisible = true
      this.$nextTick(() => {
        this.$refs.pswForm.clearValidate()
      })
    },

    submitResetPassword () {
      /**
       * 提交重置密码
       */
      this.$refs.pswForm.validate(valid => {
        if (valid) {
          this.$request.post({
            url: this.$apis.saveUser,
            data: {
              id: this.currentUser.id,
              ...this.pswFormData
            }
          }).then(res => {
            if (res?.code === 1000) {
              this.$message.success(this.$t('passwordResetSuccessful'))
              this.changePasswordVisible = false
            }
          })
        }
      })
    },

    submitQuery () {
      if (!this.queryFormData.name) {
        this.queryVisible = false
        return
      }
      this.$request.post({
        url: this.queryUrl,
        data: {
          queries: [{
            calculator: 'like',
            connector: 'AND',
            fieldName: 'name',
            value: this.queryFormData.name
          }]
        }
      }).then(data => {
        if (data?.code === 1000) {
          this.users = data.data.records
          this.total = data.data.total
          const msg = `${this.$t('querySuccess_1')}${this.total}${this.$t('querySuccess_2')}`
          this.$message.success(msg)
          this.queryVisible = false
        }
      })
    },
    getTeamAndUsers () {
      /* get current Company's team list */
      this.getTeamListByCompany(this.currentCompany)
      /* get all current Company's uers */
      this.onUserHandleSuccess()
    }
  },

  watch: {
    'currentCompany.id': {
      handler (value) {
        if (value) {
          const { id: userId } = this.$store.state.user
          this.getFunctionsByUser(userId, value)
          this.getTeamAndUsers();
        }
      },
      immediate: true
    },
    '$route' (to) {
      const { companyId } = to.params
      this.currentCompany = this.companyList.find(item => item.id === companyId) || {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import "index";
</style>

<style scope>
  .icon-color {
    color: white;
  }
</style>
